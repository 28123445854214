$perfect-auto-warranty-black-lead: #464c4d;
$perfect-auto-warranty-autumn-glory: #ff870b;
$perfect-auto-warranty-shining-silver: #c8c8c8;
$perfect-auto-warranty-bleached-silk: #f3f2f2;
$perfect-auto-warranty-black-beauty: #28252b;
$perfect-auto-warranty-battleship-grey: #6d7577;
$perfect-auto-warranty-winter-solstice: #47525d;
$perfect-auto-warranty-puritan-grey: #a8aeaf;
$perfect-auto-warranty-nero: #242626;
$perfect-auto-warranty-dire-wolf: #272828;
$perfect-auto-warranty-placebo-blue: #ebf4fd;
$perfect-auto-warranty-chi-gong: #ff1919;
$perfect-auto-warranty-mississippi-river: #386189;
$perfect-auto-warranty-spiro-disco-ball: #0ac5ff;
$offer-border-color: #e4e7eb;

:export {
    blackLead: $perfect-auto-warranty-black-lead;
    autumnGlory: $perfect-auto-warranty-autumn-glory;
    shiningSilver: $perfect-auto-warranty-shining-silver;
    bleachedSilk: $perfect-auto-warranty-bleached-silk;
    blackBeauty: $perfect-auto-warranty-black-beauty;
    battleshipGrey: $perfect-auto-warranty-battleship-grey;
    winterSolstice: $perfect-auto-warranty-winter-solstice;
    puritanGrey: $perfect-auto-warranty-puritan-grey;
    nero: $perfect-auto-warranty-nero;
    direWolf: $perfect-auto-warranty-dire-wolf;
    placeboBlue: $perfect-auto-warranty-placebo-blue;
    chiGong: $perfect-auto-warranty-chi-gong;
    mississippiRiver: $perfect-auto-warranty-mississippi-river;
    spiroDiscoBall: $perfect-auto-warranty-spiro-disco-ball;
    offerBorderColor: $offer-border-color;
}
